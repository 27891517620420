<template>
	<div id="main">
		<h1>Your Brand Kit Upgrade was Canceled</h1>
		<p>
			You canceled your upgrade. If this wasn't intentional please try
			upgrading again by clicking the link below:
		</p>
		<p>
			View your brand kits order here:
			<a
				v-text="orderId"
				@click="$router.push(`/brand-kit/${orderId}`)"
			></a>
		</p>
	</div>
</template>

<script>
	export default {
		name: 'BrandKitCancel',
		props: {
			orderId: {
				type: String,
				default: ''
			}
		},
		mounted() {
			localStorage.setItem('brandKitToUpgrade', '');
		}
	};
</script>

<style scoped>
	#main {
		max-width: 90%;
		margin: 0 auto;
		padding-top: 15vh;
	}

	h1,
	p,
	a {
		color: var(--black);
		text-align: center;
	}

	a {
		color: var(--text);
		text-decoration: underline;
	}

	a:hover {
		cursor: pointer;
		color: var(--accent-blue);
	}
</style>
